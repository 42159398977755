.App {
  background-color: #fff;
  color: #1d1d1d;
}


.hero-cta{
  max-width: 220px;
  padding: 12px;
  font-size: 13pt;
  font-weight: 400;
  color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f2f2f2;
  border-radius: 8px;
  cursor: pointer;
}
.btn-icon{
  filter: invert(100%);
  margin-right: 8px;
}
.footer{
  width:100%;
  padding-bottom: 48px;
  height: 36vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #37702b;
  color: #f2f2f2;
}

.ftr-logo{
  font-size: 48px;
  font-weight: 600;
  margin: 16px;
}

.ftr-content{
  list-style-type: none;
  font-size: 20px;
  margin: 0 8px 8px 0;
}

.ftr-content li{
  margin: 8px;
  cursor: pointer;
}
.fw{
  margin-bottom: -16px;
}
.navbar{
  max-width: 100%;
  height: 56px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navbar ul li {
  margin: 12px;
}
.navbar ul li a {
  color: #37702b;
  font-size: 16px;
  font-weight: 700;
}

.logo {
  max-width: 136px;
  max-height: 136px;
}
.logo img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.hero{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
.htc{
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.htc h3{
 font-size: 44px;
 line-height: 52px;
 letter-spacing: -0.41px;
 color: #3e8032;
 text-align: start;
 margin-bottom: -0.1px;
 max-width: 460px;
}
.htc h4{
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.5;
  max-width: 460px;
}
.cta {
  text-align: center;
  width: 180px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #37702b;
  border: 2px solid #3e8032;
  padding: 12px;
  border-radius: 8px;
  margin: 16px;
}
.hic{
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(2, 1fr);
grid-column-gap: 2px;
grid-row-gap: 2px;
  width: 35%;
  margin: 4px;
  height: 400px;
  margin-left: -72px;
}
.card {
  color: #f5f5f5;
  display: flex; 
  align-items: center;
  justify-content: center;
   width: 214px;
    height: 182px; 
    border-radius: 8px; 
    margin: 12px;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.2px;
    line-height: 28px;
    cursor: pointer;
  }
.div1 { grid-area: 1 / 1 / 2 / 2;  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./vishnu-r-nair-m1WZS5ye404-unsplash.jpg'); background-position: center;background-size: cover;}
.div2 { grid-area: 2 / 2 / 3 / 3; background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./6201_7_2019-11-12lydol-slameuse-decouverte-fi.jpg'); background-position-x: right;background-position-y: top;background-size: cover;}
.div3 { grid-area: 1 / 2 / 2 / 3; background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./artworks-NFnhsbFjll3H3Rkk-mlweGg-t500x500.jpg'); background-position-x: center;background-position-y: top;background-size: cover;}
.div4 { grid-area: 2 / 1 / 3 / 2; background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./Dipanda-660x330.jpg'); background-position-x: right;background-position-y: top;background-size: cover;}

.why{
  padding: 60px 80px;
}

.why-h3{
  padding-left: 40px;
  font-size: 36px;
  font-weight: 700;
  text-align: left;
  color: #37702b;
}
.msc{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 32px;
}
.amc{
    display: flex;
  align-items: center;
  justify-content: center;
   
}
.imc{ 
  width: 188px;
  height: 152px;
  background-color: #3e8032;
  border-radius: 8px;
}

.tc{
  display: block;
  margin-left: 16px;
  width: 300px;
}

.tc span {
    font-size: 18px;
  line-height: 24.84px;
  font-weight: medium;
}
.create{
  display: flex;
align-items: center; 
justify-content: center;
 padding: 60px 60px;
}
.stc{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4px;
}
.ilc{
  width: 248px;
  height: 220px;
  background-color: #3e8032;
}
.otc{
      font-size: 18px;
  line-height: 24.84px;
  font-weight: medium;
  text-align: center;
  margin-top: 36px;
}
.create-h3{
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: #37702b;
  max-width: 460px;
}

.scta{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 80px;
}
.secta {
  text-align: center;
  width: 180px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #f5f5f5;
  background-color:  #3e8032;
  padding: 12px;
  border-radius: 8px;
  margin: 16px;
}